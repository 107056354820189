// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Make place for the overview map button */
#BottomBar span.bottombar-links {
    margin-right: 3em;
}

div.overview-map {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 101;
}

div.overview-map > button {
    font-size: initial!important;
    right: 0.25em;
    bottom: 0.25em;
    position: absolute;
    color: var(--map-button-text-color)!important;
    background-color: var(--map-button-bg-color)!important;
    border: 0!important;
    outline: 0;
    z-index: 101;
}

div.overview-map > div.ol-overviewmap-map {
    position: absolute;
    right: 1px;
    bottom: 1px;
    width: 200px;
    height: 200px;
    background-color: white;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
    border: 1px solid var(--border-color);
}

div.overview-map.ol-collapsed > div.ol-overviewmap-map {
    display: none;
}
`, "",{"version":3,"sources":["webpack://./qwc2/plugins/map/style/OverviewSupport.css"],"names":[],"mappings":"AAAA,2CAA2C;AAC3C;IACI,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,QAAQ;IACR,YAAY;AAChB;;AAEA;IACI,4BAA4B;IAC5B,aAAa;IACb,cAAc;IACd,kBAAkB;IAClB,6CAA6C;IAC7C,sDAAsD;IACtD,mBAAmB;IACnB,UAAU;IACV,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,0CAA0C;IAC1C,qCAAqC;AACzC;;AAEA;IACI,aAAa;AACjB","sourcesContent":["/* Make place for the overview map button */\n#BottomBar span.bottombar-links {\n    margin-right: 3em;\n}\n\ndiv.overview-map {\n    position: absolute;\n    bottom: 0;\n    right: 0;\n    z-index: 101;\n}\n\ndiv.overview-map > button {\n    font-size: initial!important;\n    right: 0.25em;\n    bottom: 0.25em;\n    position: absolute;\n    color: var(--map-button-text-color)!important;\n    background-color: var(--map-button-bg-color)!important;\n    border: 0!important;\n    outline: 0;\n    z-index: 101;\n}\n\ndiv.overview-map > div.ol-overviewmap-map {\n    position: absolute;\n    right: 1px;\n    bottom: 1px;\n    width: 200px;\n    height: 200px;\n    background-color: white;\n    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);\n    border: 1px solid var(--border-color);\n}\n\ndiv.overview-map.ol-collapsed > div.ol-overviewmap-map {\n    display: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
